<template>
   <div>
      <loadingScreen :isLoading="isLoading"/>
      <div class="row justify-content-md-center">
         <div class="login-screen">
            <div class="login-box">
               <a href="#" class="login-logo">
                  <img style="max-width: 200px; margin:auto" src="@/assets/img/logo.png" alt="R2 Soft">
               </a>
               <h5>
                  {{
                     isCreate ? t('GENERAL.MSG.PLEASE_CHOOSE_YOUR_PASSWORD') : t('GENERAL.MSG.FILL_THE_BOTTOM_DATA_TO_CHANGE_PASSWORD')
                  }}
               </h5>
               <div class="row gutters">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                     <div class="form-group">
                        <input type="password" class="form-control"
                               :placeholder="isCreate ? t('GENERAL.PASSWORD')+'*' : t('GENERAL.NEW_PASSWORD') +'*'"
                               id="password" name="password" v-model="password"
                               @keyup="clearPassword()"/>
                        <div class="validation" id="invalidPassword" style="display: none">{{ msgPassword }}</div>
                        <small id="passwordHelpInline" class="text-muted"> {{ t('GENERAL.MSG.MAX_MIN_PASSWORD') }} </small>
                     </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                     <div class="form-group">
                        <input type="password" class="form-control"
                               :placeholder="isCreate ? t('GENERAL.CONFIRM_PASSWORD')+'*' : t('GENERAL.CONFIRM_NEW_PASSWORD') +'*'"
                               v-model="passwordConfirm" name="passwordConfirm" id="passwordConfirm"
                               @keyup="clearPassword()"/>
                        <div class="validation" id="invalidpasswordConfirm" style="display: none">
                           {{ t('GENERAL.MSG.REQUIRED_FIELD') }}
                        </div>
                     </div>
                  </div>
               </div>
               <hr>
               <div class="actions align-right">
                  <span class="btn btn-primary"
                        @click="update()"> {{ isCreate ? t('ACTIONS.REGISTER') : t('ACTIONS.UPDATE') }}
                  </span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import User from '@/services/user';
import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import LoadingScreen from '@/components/layouts/loadScreenComponent.vue';

export default {
   name: 'resetPassword',
   mixins: [Validate],
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {
         t,
         toast
      }
   },

   components: {
      LoadingScreen,
   },

   data() {
      return {
         form: null,
         password: null,
         isCreate: null,
         isLoading: null,
         passwordConfirm: null
      }
   },

   mounted() {
      this.isCreate = (this.$route.name == 'CreatePassword');
   },

   methods: {
      clearPassword(){
         if (this.password){
            this.password = this.password.trim();
         }
         if (this.passwordConfirm){
            this.passwordConfirm = this.passwordConfirm.trim();
         }
         this.inputPassword(this.password, this.passwordConfirm, false)
      },
      update() {
         this.invalid = [];

         this.passwordValidade(this.password, this.passwordConfirm);

         if (this.invalid.length == 0) {
            this.isLoading = true;
            this.startForm();

            User.resetPassword(this.form).then(resp => {
               this.isLoading = false;

               if (resp.data.success == true) {
                  this.toast.success(this.t('GENERAL.MSG.PLACEHOLDER_UPDATE_SUCCESS', {name: this.t('GENERAL.PASSWORD')}));
               }

               this.$router.push({name: 'Login'});
            }).catch(error => {
               this.isLoading = false;
               this.error(error);
            });
         }
      },

      startForm() {
         let email = atob(this.$route.params.email.toString());
         this.form = {
            email: email,
            token: this.$route.params.token,
            password: this.password,
            password_confirmation: this.passwordConfirm
         }
      }
   }
}
</script>